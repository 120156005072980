import moment from 'moment';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import { FormData, Critica } from '../protocols';
import { createCritica } from './CreateCritica';
import { Parcela } from '../protocols';

export function financeiroValidation(
  formData: FormData,
  parcelas: Parcela[],
): Critica[] {
  const criticas: Critica[] = [];

  const validaParcelas = () => {
    const countParcelas = parcelas.length;
    if (countParcelas === 0) {
      const critica = createCritica(
        2,
        'Financeiro deve ser informado.',
        `
        Clique em (re)calcular para assumir as condições do fornecedor ou adicione as parcelas manualmente.
        `,
      );
      criticas.push(critica);
    }
  };

  const validaTotalParcelas = () => {
    const totalNota = formData.val_total_nf;
    const totalParcelas = parcelas.reduce(
      (acumulador, item) =>
        acumulador + transformAsCurrency(item.val_parcela || 0),
      0,
    );
    if (
      transformAsCurrency(totalParcelas).toFixed(2) !==
      transformAsCurrency(totalNota).toFixed(2)
    ) {
      const critica = createCritica(
        2,
        'Total das parcelas difere do total da NF.',
        `
        A soma dos valores das parcelas deve ser igual ao total da nota.
        `,
      );
      criticas.push(critica);
    }
  };

  const validaDataVencimento = () => {
    const dtaEntrada = moment(formData.dta_entrada);
    parcelas.map((parcela) => {
      const dtaVencimento = moment(parcela.dta_vencimento);
      if (dtaEntrada.isAfter(dtaVencimento)) {
        const critica = createCritica(
          2,
          'Data de Vencimento deve ser maior que a data de Entrada da NF',
          `
          Data de entrada: ${moment(dtaEntrada).format('DD/MM/YYYY')} \n
          Data de vencimento: ${moment(dtaVencimento).format('DD/MM/YYYY')}
          `,
        );
        criticas.push(critica);
      }
      return parcela;
    });
  };

  validaParcelas();
  validaTotalParcelas();
  validaDataVencimento();

  return criticas;
  return [];
}
