import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import { FormData, Critica } from '../protocols';
import { createCritica } from './CreateCritica';

type TotalItens = {
  totalIpiItens: number;
};

export function nfItensValidation(formData: FormData): Critica[] {
  const criticas: Critica[] = [];

  const calculaTotaisItens = (): TotalItens => {
    let totalIpiItens = 0;

    formData.itens.map((item) => {
      totalIpiItens += item.val_ipi * item.qtd_entrada || 0;
      return item;
    });

    return {
      totalIpiItens,
    };
  };

  const totais = calculaTotaisItens();

  const validaTotalIPI = () => {
    const valTotIpi = formData.val_total_ipi || 0;
    const valIpiCalculado = totais.totalIpiItens;

    if (
      transformAsCurrency(valTotIpi).toFixed(2) !==
      transformAsCurrency(valIpiCalculado).toFixed(2)
    ) {
      const critica = createCritica(
        1,
        'Total informado de IPI difere do total calculado',
        `Total do IPI informado: ${valTotIpi.toFixed(
          2,
        )} \n Total do IPI calculado: ${valIpiCalculado.toFixed(2)}`,
      );
      criticas.push(critica);
    }
  };

  const validaValoresItens = () => {
    formData.itens.map((item) => {
      const qtdEntrada = item.qtd_entrada;
      const valTabela = item.val_tabela || 0;
      const qtdEmbalagem = item.qtd_embalagem;
      const valCustoRep = item.val_custo_rep;

      if (qtdEntrada < 0 || valTabela <= 0 || qtdEmbalagem <= 0) {
        const critica = createCritica(
          1,
          'Produto com quantidade inválida',
          `
        ${item.cod_produto} - ${item.des_produto} \n
        Qtd. entrada: ${formatCurrencyAsText(qtdEntrada)} \n
        Valor tabela: ${formatCurrencyAsText(valTabela)} \n
        Qtd. embalagem: ${formatCurrencyAsText(qtdEmbalagem)}
        `,
        );
        criticas.push(critica);
      }

      if (valCustoRep <= 0) {
        const critica = createCritica(
          1,
          'Custo reposição negativo',
          `
        ${item.cod_produto} - ${item.des_produto} \n
        Custo rep. negativo: ${formatCurrencyAsText(valCustoRep)} \n
        `,
        );
        criticas.push(critica);
      }
      return item;
    });
  };

  validaTotalIPI();
  validaValoresItens();

  return criticas;
}
